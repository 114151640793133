window._ = require( 'lodash' );
window.Popper = require( 'popper.js' ).default;
window.Tether = require( 'tether' );
window.$ = window.jQuery = require( 'jquery' );
require( 'bootstrap' );

window.axios = require( 'axios' );
window.axios.defaults.headers.common[ 'X-Requested-With' ] = 'XMLHttpRequest';
let token = document.head.querySelector( 'meta[name="csrf-token"]' );
if ( token ) {
	window.axios.defaults.headers.common[ 'X-CSRF-TOKEN' ] = token.content;
}
else {
	console.error( 'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token' );
}

// api_token 세팅
let apiToken = document.querySelector( 'meta[name="api-token"]' ).content;
if ( apiToken ) {
	window.axios.defaults.headers.common[ 'Authorization' ] = 'Bearer ' + apiToken;
}

window.Moment = require( 'moment' );
require('moment-timezone');
window.Dropzone = require( 'dropzone' );
window.feather = require( 'feather-icons' );

import flatpickr from "flatpickr";
import { Korean } from 'flatpickr/dist/l10n/ko.js';

require( "flatpickr/dist/themes/airbnb.css" );
window.lottie = require('lottie-web'); // lottie-web

window.flatpickrConfig = {
	locale: Korean,
	enableTime: true,
	dateFormat: "Y-m-d H:i",
	time_24hr: true,
	defaultDate: new Moment().format( 'YYYY-MM-DD HH:mm' )
};

flatpickr( document.querySelectorAll( '.datetimepicker' ), flatpickrConfig );
flatpickr( document.querySelectorAll('.datepicker'), {
	locale: Korean,
	enableTime:false,
	dateFormat: "Y-m-d"
});

import Chart from 'chart.js';

// polyfill
// closest
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
	                            Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
