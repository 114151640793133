require( './bootstrap' );

// laracast flash
$( '#flash-overlay-modal' ).modal();
$( 'div.alert' ).not( '.alert-important' ).delay( 3000 ).fadeOut( 350 );
feather.replace();
document.addEventListener( 'DOMContentLoaded', e => {
	//magnific popup common setting
	$( '.popup-image' ).magnificPopup( { type: 'image' } );
} );

const primaryNavigation = document.querySelector( '.primary-menu' );
if ( primaryNavigation ) {
	let supportPassive = false;
	try {
		let opts = Object.defineProperty( {}, 'passive', {
			get: function () {
				supportsPassive = true;
			}
		} );
	}
	catch ( err ) {
	}

	primaryNavigation.querySelectorAll( 'li > a' ).forEach( link => {
		if ( link.nextElementSibling ) {
			link.addEventListener( 'touchend', e => {
				e.preventDefault();
				const submenu = link.closest( 'li' ).querySelector( 'ul.hidden-sub' );
				if ( !submenu ) {
					location.href = link.href;
				}
				else {
					const visibleMenu = primaryNavigation.querySelector( '.visible-sub' );
					if ( visibleMenu ) {
						primaryNavigation.querySelector( '.visible-sub' ).classList.replace( 'visible-sub', 'hidden-sub' );
					}
					submenu.classList.replace( 'hidden-sub', 'visible-sub' );
				}
			}, supportPassive ? { passive: true } : false );
		}
	} );
}


// 화면 스크롤 및 고투탑 버튼 처리
const topBtn = document.querySelector( '#goto-top' );
if ( topBtn ) {
	topBtn.addEventListener( 'click', e => {
		window.scrollTo( 0, 0 );
	} );
	document.addEventListener( 'scroll', e => {
		const offsetY = (window.scrollY + window.innerHeight) / document.body.clientHeight * 100;
		if ( offsetY > 30 ) {
			topBtn.style.display = 'flex';
		}
		else {
			topBtn.style.display = 'none';
		}
	} );
}


window.getCurrentScrollPercentage = () => {
	return (window.scrollY + window.innerHeight) / document.body.clientHeight * 100
};


$( '#flash-overlay-modal' ).modal();
$( '.toast' ).toast( {
	                     animation: true,
	                     autohide: false,
	                     delat: 300
                     } );

window.passwordValidation = ( str ) => {
	const rule = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

	if ( !str.match( rule ) ) {
		return false;
	}

	return true;
};

window.emailValidation = ( str ) => {
	const rule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

	if ( !str.match( rule ) ) {
		return false;
	}

	return true;
};

window.domainValidation = ( str ) => {
	const rule = /^[A-Za-z0-9+]*$/;
	if ( !str.match( rule ) ) {
		return false;
	}
	return true;
};

window.makeSlug = async ( str ) => {
	try {
		const response = await axios.get( '/api/helper/make-slug', {
			params: {
				string: str
			}
		} );
		return response.data.slug;
	}
	catch ( error ) {
		treatError( error );
	}
};

window.showMessage = ( msg, status, hide = false ) => {
	const container = document.getElementById( 'toast-container' );
	const tg = document.getElementById( 'msg-window' );
	const header = tg.querySelector( '.toast-header' );
	const body = tg.querySelector( '.toast-body' );
	const sts = header.querySelector( '.toast-status' );

	body.innerHTML = msg;

	if ( status === 'error' ) {
		sts.textContent = '오류';
	}
	else {
		sts.textContent = '알림';
	}
	let bg;
	switch ( status ) {
		case 'info' :
			bg = 'bg-info';
			break;
		case 'primary' :
			bg = 'bg-primary';
			break;
		case 'warning' :
			bg = 'bg-warning';
			break;
		case 'success' :
			bg = 'bg-success';
			break;
		case 'error':
		case 'danger' :
			bg = 'bg-danger';
			break;
		case 'gradient':
		case 'special' :
			bg = 'bg-gradient';
			break;
		default :
			bg = 'bg-light';
	}

	header.classList.remove( 'bg-danger', 'bg-primary', 'bg-info', 'bg-gradient', 'bg-light', 'bg-warning', 'bg-success' );
	header.classList.add( bg );
	if ( bg === 'bg-light' ) {
		header.classList.add( 'text-dark' );
	}

	$( tg ).toast( {
		               animation: true,
		               autohide: hide,
		               delay: 500
	               } );

	if ( container ) {
		container.style.display = '';
	}

	$( tg ).toast( 'show' );
	if ( hide ) {
		setTimeout( () => {
			$( tg ).toast( 'dispose' );
			if ( container ) {
				container.style.display = 'none';
			}
		}, 2000 );
	}
};


window.activeSidemenu = ( id ) => {
	if ( !document.getElementById( id ).classList.contains( 'active' ) ) {
		document.getElementById( id ).classList.add( 'active' )
	}
};

window.checkAll = ( obj ) => {
	const items = obj.closest( '.table' ).querySelectorAll( 'tbody input[type="checkbox"]' );
	items.forEach( el => {
		el.checked = obj.checked;
	} );
};

window.treatError = ( error ) => {
	if ( error.response ) {
		console.log( error.response );
		showMessage( error.response.data.message, 'error', true );
	}
	else if ( error.request ) {
		console.log( error.request );
		showMessage( error.request.response.message, 'error', true );
	}
	else {
		console.log( error );
		showMessage( '오류 발생 : ' + error.message, 'error', true );
	}

};

window.file_download = ( url, filename ) => {
	axios( {
		       url: url,
		       method: 'GET',
		       responseType: 'blob', // important
	       } )
			.then( ( response ) => {
				const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
				const link = document.createElement( 'a' );
				link.href = url;
				link.setAttribute( 'download', filename );
				document.body.appendChild( link );
				link.click();
			} );
};

// 미디어 불러오기
window.getMedias = async ( church_id, page= 1 ) => {
	return axios.get( '/api/medias', {
		params: {
			church_id: church_id,
			page: page
		}
	} );
};

// 미디어 다운로드
window.downloadMedia = ( endpoint, filename ) => {
	axios( {
		       url: endpoint,
		       method: 'GET',
		       responseType: 'blob'
	       } )
			.then( response => {
				const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
				const link = document.createElement( 'a' );
				link.href = url;
				link.setAttribute( 'download', filename );
				document.body.appendChild( link );
				link.click();
			} )
			.catch( err => {
				if ( err.response && err.response.status === 404 ) {
					showMessage( '파일이 존재하지 않습니다.', 'error', true );
				}
				else if ( err.response && err.response.status !== 404 ) {
					showMessage( err.response.message, 'error', false );
				}
				else {
					showMessage( err.message, 'error', false );
				}
			} )
};

// 미디어 삭제
window.removeMedia = async obj => {
	try {
		await axios.delete( obj.dataset.endpoint );
		$( obj ).tooltip( 'dispose' );
		obj.closest( '.media-item' ).classList.add( 'animated', 'fadeOutRight' );
		setTimeout( () => {
			obj.closest( '.media-item' ).remove();
		}, 500 );
	}
	catch ( err ) {
		if ( err.response ) {
			showMessage( err.response.data.message, 'error', false );
		}
		else {
			showMessage( err.message, 'error', false );
		}
	}
};

// 임시 글 작성 함수
window.tempCreatePost = async ( church_id, user_id ) => {
	try {

		const response = await axios.post( `/api/church/${church_id}/posts/create`, {
			'user_id': user_id,
			'church_id': church_id
		} );

		return {
			post: response.data,
			update_url: `/api/church/${church_id}/posts/${response.data.id}`,
			fileupload_url: `/api/attaches/${response.data.id}`
		};
	}
	catch ( error ) {
		treatError( error );
	}
};

window.summernoteConfig = {
	lang: 'ko-KR',
	minHeight: 450,
	focus: true,
	placeholder: '내용을 입력하세요...',
	fontNames: [ '고딕', '명조' ],
	disableDragAndDrop: false,
	blockquoteBreakingLevel: 2,
	toolbar: [
		[ 'style', [ 'style' ] ],
		[ 'font', [ 'bold', 'underline', 'clear', 'height' ] ],
		[ 'color', [ 'color' ] ],
		[ 'para', [ 'ul', 'ol', 'paragraph' ] ],
		[ 'insert', [ 'table', 'link' ] ],
		[ 'view', [ 'fullscreen', 'codeview' ] ],
	],
};


// 글 작성 시작할때 임시글을 저장한다.
window.formInit = async ( form ) => {

	$( '#contents' ).summernote( summernoteConfig );
	SetupDropzone();

	const church_id = form.dataset.churchId;
	let post_id = null;
	let fileupload_url = "";

	if ( form.dataset.formMode === 'create' ) {
		const response = await tempCreatePost( church_id, form.user_id.value );
		form.category_id.value = response.post.category_id;
		const categoryQuery = location.search.match( /category_id=[\d]{1,3}/ );
		if ( categoryQuery ) {
			form.category_id.value = categoryQuery[ 0 ].split( '=' )[ 1 ];
		}
		form.action = response.update_url;
		form.status.value = 'publish';

		const published_at = flatpickr( form.published_at, flatpickrConfig );

		post_id = response.post.id;
		published_at.setDate( response.post.published_at );
		fileupload_url = response.fileupload_url;

	}
	else if ( form.dataset.formMode === 'edit' ) {
		const actionArray = form.action.split( '/' );

		post_id = actionArray[ actionArray.length - 1 ];
		fileupload_url = `/api/attaches/${post_id}`;
	}

	myDropzone.options.headers = {
		'X-CSRF-TOKEN': document.head.querySelector( 'meta[name="csrf-token"]' ).content,
		'Authorization': 'Bearer ' + document.querySelector( 'meta[name="api-token"]' ).content
	};

	myDropzone.options.url = fileupload_url;

	myDropzone.options.params = {
		'church_id': church_id,
		'attachable_id': post_id,
		'attachable_type': 'post'
	};

	form.title.focus();
};

const featuredImage = document.querySelector( 'input[name="featured_image"]' );
if ( featuredImage ) {
	featuredImage.addEventListener( 'change', evt => {
		const label = evt.target.closest( '.custom-file' ).querySelector( 'label' );
		label.textContent = evt.target.value;
	} );
}

// 글 validation
window.validateForm = ( form ) => {
	if ( !form.title.value ) {
		form.title.focus();
		showMessage( '제목을 입력하세요.', 'error', false );
		return false;
	}
	if ( !form.contents.value ) {
		if ( !confirm( '내용 없이 저장하시겠습니까?' ) ) {
			form.contents.focus();
			showMessage( '내용을 입력하세요.', 'error', false );
			return false;
		}
	}
	if ( !form.category_id.value ) {
		form.category_id.focus();
		showMessage( '카테고리를 선택하세요.', 'error', false );
		return false;
	}
	if ( !form.status.value ) {
		form.status.focus();
		showMessage( '저장 옵션을 선택하세요.', 'error', false );
		return false;
	}
	if ( form.status.value && form.status.value === 'temp' ) {
		showMessage( '저장 옵션이 임시저장입니다. 글은 사용자에게 보이지 않습니다.', 'info', true );
	}
	if ( !form.published_at.value ) {
		let now = new Moment();
		form.published_at.value = now.format( 'YYYY-MM-DD HH:mm' );
	}

	return true;
};

// 대표이미지 삭제
window.deleteFeaturedImage = ( post_id ) => {
	if ( !confirm( '대표 이미지를 삭제할까요?' ) ) return false;
	axios.delete( `/api/posts/${post_id}/featured_image` )
	     .then( r => {
		     if ( r.status === 209 ) {
			     const previewContainer = document.querySelector( '#featured-image-preview' );
			     previewContainer.classList.add( 'animated', 'fadeOut' );
		     }
	     } )
	     .catch( error => {
		     treatError( error );
	     } )
};

// spinner
window.spinner = {
	'show': () => {
		const tpl = document.querySelector( '#spinner' ).content.cloneNode( true );
		document.body.appendChild( tpl );
	},
	'hide': () => {
		const wrapper = document.querySelector( '#spinner-wrapper' );
		if ( wrapper ) {
			wrapper.remove();
		}
	}
};

// 게시글 삭제
window.deletePosts = church_id => {
	const items = document.querySelectorAll( 'input[name="post_id[]"]:checked' );

	if ( items.length === 0 ) {
		showMessage( '삭제할 글을 선택하세요.', 'warning', false );
		return false;
	}

	spinner.show();

	items.forEach( async el => {
		try {
			const response = await axios.delete( `/api/church/${church_id}/posts/${el.value}` );
			if ( response.status === 209 ) {
				await deleteSitemapChild( church_id, `${el.dataset.publicUrl}` ); // 사이트맵에서 삭제
				el.closest( 'tr' ).classList.add( 'animated', 'bounceOutRight' );
				setTimeout( () => {
					el.closest( 'tr' ).remove();
				}, 500 );
			}
		}
		catch ( err ) {
			console.log( err.response ? err.response : (err.request ? err.request : err) );
		}

	} );

	spinner.hide();
};

// dropzone 설정
window.SetupDropzone = () => {
	const previewNode = document.querySelector( "#dropzone-template" );
	previewNode.id = "";
	const previewTemplate = previewNode.parentNode.innerHTML;
	previewNode.parentNode.removeChild( previewNode );

	const dropzoneConfig = {
		url: '#',
		method: 'post',
		withCredentials: true,
		paramName: 'file',
		thumbnailWidth: 80,
		thumbnailHeight: 80,
		parallelUploads: 20,
		previewTemplate: previewTemplate,
		autoQueue: true,
		previewsContainer: "#dropzone-previews", // Define the container to display the previews
		clickable: "#click-to-upload" // Define the element that should be used as click trigger to select files.
	};

	window.myDropzone = new Dropzone( document.getElementById( 'upload-zone' ), dropzoneConfig );

	myDropzone.on( "addedfile", function ( file ) {
		feather.replace();
	} );

	// Update the total progress bar
	myDropzone.on( "totaluploadprogress", function ( progress ) {
		const uploadProgressBar = document.querySelector( "#total-progress .progress-bar" );
		uploadProgressBar.style.width = `${progress}%`;
		uploadProgressBar.setAttribute( 'aria-valuenow', progress );
		uploadProgressBar.querySelector( '.skills-info span' ).textContent = `${progress}%`;
	} );

	myDropzone.on( "sending", function ( file ) {
		// Show the total progress bar when upload starts
		document.querySelector( "#total-progress" ).style.opacity = "1";
	} );

	myDropzone.on( 'complete', function ( file ) {

		const response = JSON.parse( file.xhr.response );
		const appendBtn = file.previewElement.querySelector( '[data-dz-add-to-contents]' );
		const removeBtn = file.previewElement.querySelector( '[data-dz-remove].delete' );

		appendBtn.addEventListener( 'click', e => {
			e.preventDefault();
			if ( is_image( response.file.mimetype ) ) {
				const img = document.createElement( 'img' );
				img.classList.add( 'img-fluid', 'my-2' );
				img.id = `attach-${response.file.id}`;
				img.src = response.url;
				img.alt = response.url.split( '/' )[ response.url.split( '/' ).length - 1 ];
				$( '#contents' ).summernote( 'insertNode', img );
			}
			else {
				const link = document.createElement( 'a' );
				link.classList.add( 'btn', 'btn-link', 'my-2' );
				link.id = `attach-${response.file.id}`;
				link.href = response.url;
				link.target = '_blank';
				$( '#contents' ).summernote( 'insertNode', link );
			}
		} );

		removeBtn.addEventListener( 'click', async e => {
			e.preventDefault();
			axios.delete( response.remove_url )
			     .then( r => {
				     file.previewElement.remove();
				     const appendedFile = document.querySelector( `#contents #attach-${response.file.id}` );
				     if ( appendedFile ) {
					     appendedFile.remove();
				     }
			     } )
			     .catch( err => {
				     console.log( err );
			     } )
		} );

		feather.replace();
	} );

	// Hide the total progress bar when nothing's uploading anymore
	myDropzone.on( "queuecomplete", function ( progress ) {
		document.querySelector( "#total-progress" ).style.opacity = "0";
	} );

	// 에러 처리
	myDropzone.on( "error", ( file, error ) => {
		console.log( error );
	} );

};

// 글 저장
window.postFormSubmit = ( form ) => {

	validateForm( form );

	const fd = new FormData( form );
	if ( form.featured_image.files.length > 0 ) {
		fd.append( 'featured_image', form.featured_image.files[ 0 ] );
	}

	return axios.post( form.action, fd );

};

// 첨부파일 삭제
window.deleteAttach = ( obj ) => {

	if ( !confirm( '파일을 삭제할까요?' ) ) return false;

	const url = obj.dataset.attachDelete;
	const src = obj.dataset.src;

	axios.delete( url )
	     .then( r => {
		     if ( r.status === 209 ) {
			     const attachedFromBody = document.querySelector( `#contents img[src="${src}"]` );
			     if ( attachedFromBody ) {
				     attachedFromBody.closest( 'a' ).classList.add( 'animated', 'filpOutY' );
			     }
			     obj.closest( '.portfolio-item' ).classList.add( 'animated', 'filpOutY' );
			     setTimeout( () => {
				     obj.closest( '.portfolio-item' ).remove();
				     if ( attachedFromBody ) {
					     if ( attachedFromBody.closest( 'p' ) ) {
						     attachedFromBody.closest( 'p' ).remove();
					     }
					     else {
						     attachedFromBody.closest( 'a' ).remove();
					     }
				     }
			     }, 500 );
		     }
	     } )
};

// 본문에 이미지 추가
window.attachToContents = ( obj ) => {
	const mimetype = obj.dataset.mimetype;
	const url = obj.dataset.attachUrl;
	const link = document.createElement( 'a' );
	const filename = obj.dataset.filename;

	if ( is_image( mimetype ) ) {
		link.href = url;
		link.classList.add( 'popup-gallery' );

		const img = document.createElement( 'img' );
		img.src = url;
		img.alt = filename;
		img.style.maxWidth = '100%';
		img.style.height = 'auto';

		link.appendChild( img );

	}
	else {
		link.href = 'javascript:;';
		link.onclick = `file_download('${url}', '${filename}')`;
		link.textContent = filename;
	}

	$( '#contents' ).summernote( 'insertNode', link );

};

// 이미지인지 mimetype으로 구별
window.is_image = ( mimetype ) => {
	const mimes = mimetype.split( '/' )[ 0 ];
	return mimes === 'image';
};

// 비디오 임베드 코드 불러오기
window.get_video_embedcode = async ( url, width = null ) => {
	try {
		const response = await axios.post( '/api/embedcode', {
			url: encodeURIComponent( url ),
			width: width
		} );

		if ( response.data && response.data.embedcode ) {
			return response.data.embedcode;
		}
		else {
			console.log( response );
			return null;
		}
	}
	catch ( err ) {
		console.log( err.response ? err.response : (err.request ? err.request : err) );
		showMessage( '임베드 코드를 받아올 수 없습니다.', 'error', true );
		return null;
	}
};

// 비디오 입력란 추가
window.add_video = ( obj ) => {

	const container = obj.closest( '#video-container' );
	const wrapper = obj.closest( '.video-wrapper' );
	let providerCount = container.querySelectorAll( 'select[name="provider[]"]' ).length;
	let videoCount = container.querySelectorAll( 'input[name="video-url[]"]' ).length;
	const newRow = wrapper.cloneNode( true );

	let provider = newRow.querySelector( 'select[name="provider[]"]' );
	provider.id = `video-provider-${++providerCount}`;
	provider.value = 'youtube';

	let video = newRow.querySelector( 'input[name="video_url[]"]' );
	video.id = `video-url-${++videoCount}`;
	video.value = '';
	newRow.classList.add( 'animated', 'fadeInDown' );
	container.appendChild( newRow );
	$( '[data-toggle="tooltip"]' ).tooltip();

};

// 비디오 입력란 삭제
window.remove_video = async obj => {

	const container = document.getElementById( 'video-container' );
	let videoCount = document.querySelectorAll( '#video-container .video-wrapper' ).length;

	let response = null;
	if ( obj.dataset.removeUrl ) {
		try {
			response = await axios.delete( obj.dataset.removeUrl );
		}
		catch ( error ) {
			treatError( error );
		}
	}

	if ( response && response.status === 209 ) {

		if ( videoCount === 1 ) {
			const node = obj.closest( '.video-wrapper' ).cloneNode( true );
			node.querySelector( 'select[name="provider[]"]' ).value = 'youtube';
			node.querySelector( 'select[name="provider[]"]' ).id = 'video-provider-1';
			node.querySelector( 'input[name="video-url[]"]' ).value = '';
			node.querySelector( 'input[name="video-url[]"]' ).id = 'video-url-1';
			container.appendChild( node );
		}

		$( obj ).tooltip( 'dispose' );
		obj.closest( '.video-wrapper' ).remove();

	}
	else {
		if ( videoCount > 1 ) {
			$( obj ).tooltip( 'dispose' );
			obj.closest( '.video-wrapper' ).remove();
		}
	}


};

// 비디오 본문에 추가
window.insert_video = async obj => {
	const wrapper = obj.closest( '.video-wrapper' );
	if ( wrapper ) {

		const video = wrapper.querySelector( 'input[name="video_url[]"]' );

		if ( !video.value ) {
			showMessage( '추가할 비디오 주소를 입력하세요.', 'warning', false );
			return false;
		}

		const embedcode = await get_video_embedcode( video.value, null );

		if ( embedcode ) {
			const fullcode = document.createElement( 'div' );
			fullcode.classList.add( 'embed-responsive', 'embed-responsive-16by9', 'mb-md-4', 'mb-3' );
			fullcode.innerHTML = embedcode;
			$( '#contents' ).summernote( 'insertNode', fullcode );
		}

	}
};


// 오브젝트 삭제
window.objectRemove = ( obj ) => {
	obj.classList.add( 'animated', 'fadeOutRight' );
	setTimeout( () => {
		obj.remove();
	}, 500 );
};


// 스텝 필터
window.staffFilter = ( position ) => {
	document.querySelectorAll( '.staff-container' ).forEach( el => {
		if ( position === 'all' ) {
			el.style.display = '';
		}
		else {
			if ( el.dataset.position !== position ) {
				el.style.display = 'none';
			}
			else {
				el.style.display = '';
			}
		}
	} );
};

// 컬러 피커
const pickerTpl = `
<div class="colorpicker colorpicker-2x">
    <div class="colorpicker-saturation"><i class="colorpicker-guide"></i></div>
    <div class="colorpicker-hue"><i class="colorpicker-guide"></i></div>
    <div class="colorpicker-alpha">
      <div class="colorpicker-alpha-color"></div>
      <i class="colorpicker-guide"></i>
    </div>
<div class="colorpicker-bar">
	<button class="btn btn-sm btn-link w-100" type="button" onclick="applyColor(this);">적용</button>
</div>	
	`;

window.colorpickerOption = {
	inline: true,
	container: true,
	template: pickerTpl,
	format: 'rgba',
	customClass: 'colorpicker-2x',
	sliders: {
		saturation: {
			maxLeft: 200,
			maxTop: 200
		},
		hue: {
			maxTop: 200
		},
		alpha: {
			maxTop: 200
		}
	}
};


// 회원 탈퇴
window.deleteUser = obj => {
	if ( !confirm( '사이트를 탈퇴할 경우 작성하신 글은 모두 삭제됩니다.\n사이트 탈퇴를 진행할까요?' ) ) return false;
	const user_id = obj.dataset.userId;
	const church_id = obj.datset.churchId;

	axios.delete( `/api/churches/${church_id}/members/${user_id}` )
	     .then( r => {
		     alert( '사이트 탈퇴가 완료되었습니다.' );
		     location.href = '/logout';
	     } )
	     .catch( err => {
		     if ( err.response && err.response.status === 403 ) {
			     alert( err.response.data.message );
		     }
		     else {
			     alert( '오류 발생으로 인해 사이트 탈퇴를 진행할 수 없습니다.\n관리자에게 문의바랍니다.' );
		     }
	     } );

};

// 로그아웃
window.logout = () => {

	if ( !confirm( '로그아웃할까요?' ) ) return false;
	const f = document.querySelector( '#logout-form' );
	f.submit();
};

// 검색창 보기
window.showSearchForm = () => {
	const wrapper = document.getElementById( 'search-form-container' );
	const f = wrapper.querySelector( 'form' );
	f.reset();
	wrapper.style.display = '';
	f.search.focus();

	wrapper.querySelector( '.close' ).addEventListener( 'click', e => {
		f.reset();
		wrapper.style.display = 'none';
	} );
};

if ( document.querySelector( '.entry-content' ) ) {

	document.querySelectorAll( '.popup' ).forEach( el => {
		el.addEventListener( 'mouseover', e => {
			el.querySelector( 'img' ).classList.add( 'animated', 'pulse' );
		} );
		el.addEventListener( 'mouseout', e => {
			el.querySelector( 'img' ).classList.remove( 'animated', 'pulse' );
		} );
	} );

}

// 사이트맵 관련
// 글 작성 후 사이트맵에 추가
window.addSitemapChild = async ( church_id, url, lastmod ) => {
	const endpoint = `/api/sitemap/${church_id}`;
	const data = {
		url: encodeURIComponent( url ),
		loc: url,
		lastmod: lastmod
	};

	try {
		return await axios.post( endpoint, data );
	}
	catch ( err ) {
		return err.response
				? err.response
				: (err.request ? err.request : err);
	}

};

// 글 업데이트 후 사이트맵에 추가
window.editSitemapChild = async ( church_id, url ) => {
	const endpoint = `/api/sitemap/${church_id}`;
	const data = { url: encodeURIComponent( url ) };
	try {
		return await axios.put( endpoint, data );
	}
	catch ( err ) {
		return err.response
				? err.response
				: (err.request ? err.request : err);
	}

};

// 글 삭제 후 사이트맵 업데이트
window.deleteSitemapChild = async ( church_id, url ) => {
	const endpoint = `/api/sitemap/${church_id}`;
	try {
		return await axios.delete( endpoint, {
			params: {
				url: encodeURIComponent( url )
			}
		} );
	}
	catch ( err ) {
		return err;
	}
};
